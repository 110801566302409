@font-face {
  font-family: "MisakiGothic2nd";
  src: url('/fonts/misaki_gothic_2nd.eot?') format('eot'),
       url('/fonts/misaki_gothic_2nd.woff2') format('woff2'),
       url('/fonts/misaki_gothic_2nd.woff') format('woff');
}

.text-pixel {
  font-family: "MisakiGothic2nd" !important;
}

.bg-stripes {
  background-image: repeating-linear-gradient(
    45deg, 
    #f5f5f5, #f5f5f5 10px, 
    transparent 0, transparent 20px
  );
}